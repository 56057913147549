import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import ScrollspyNav from 'react-scrollspy-nav';

import '../../generic-page.scss';
import { Alert, Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const CSGearForDummiesPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Basic gearing</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/guide_gearing.png"
            alt="Beginner gearing"
          />
        </div>
        <div className="page-details">
          <h1>Basic gearing</h1>
          <h2>
            This guide explains how gear works in Counter Side and what are the
            optimal ways of obtaining and tuning it.
          </h2>
        </div>
      </div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="foreword">
              <SectionHeader
                title="Foreword"
                subtitle="Guide created by Izuhii"
              />
              <p>
                Counter:Side is a live-service game which means things can and
                will change, for better or worse. Two most prominent examples
                are the CC-Resistance (now renamed to Debuff Resistance) and the
                DMG Calculation rework. This means the guide might become
                obsolete / less relevant.
              </p>
              <p>
                If it does happen, do let us know so we can make changes to the
                guide to keep them up-to-date.
              </p>
            </div>
            <div className="section-scroll" id="importance-of-gear">
              <SectionHeader title="Importance of Gear" />
              <p>
                You may have the units and level, but you still cannot win this
                particular stage. Be it either your frontline dying too fast or
                your damage dealers not killing opponents fast enough. Granted
                there might be some hidden mechanics on that stage, but in most
                cases it's because your gears are not adequate enough.
              </p>
              <p>
                Gears boost your units stats and add some more stats they don’t
                usually have; Typically a unit only has HP, ATK, DEF, CRIT, HIT,
                EVA, and CRIT DMG. While things like Skill Haste, ASPD, X-DMG,
                and X-DMG RES are obtainable from gears. These additional stats
                are the game-changer you want to aim for so your gears can be
                called as more than adequate.
              </p>
              <p>
                Here is an example of Kestrel Xiao Lin damage without and with
                gear (Crit DMG set + her EE):
              </p>
              <StaticImage
                src="../../../images/counterside/gearing/image12.webp"
                alt="Beginner gearing"
              />
              <StaticImage
                src="../../../images/counterside/gearing/image15.webp"
                alt="Beginner gearing"
              />
            </div>
            <div className="section-scroll" id="gear-stats">
              <SectionHeader title="Gear Stats" />
              <p>
                Before we begin, do note that Counter:Side has a unique way of
                using stats to calculate damage:
              </p>
              <ul>
                <li>
                  When animation meets hitbox &gt; register a hit (can sometimes
                  be funny: things hit something they shouldn't have or vise
                  versa)
                </li>
                <li>
                  Check EVA rate &gt; if success the hit is ‘miss’ (cannot crit,
                  damage reduced)
                </li>
                <li>
                  {' '}
                  If it was a ‘miss’ &gt; DMG = (0.1+HIT%)*DMG (the more HIT you
                  have, the less important opponent’s evasion became)
                </li>
                <li>
                  If not ‘miss’ &gt; check CRIT rate &gt; if success the hit is
                  a ‘crit’ (gain cdmg multiplier)
                </li>
              </ul>
              <p>The dmg calculation in this game is explained with this:</p>
              <Alert variant="primary">
                Attacker's ATK × Damage Multiplier × (1 + Attacker's CDMG -
                Opponent's CDMG Res)* × [(1 - ΣTyped Resistance + ΣAntiDmg) × (1
                - DEF%)]** × [1 - (Universal Res + AoE Res*** + Skill/Ult*** Res
                - Skill/Ult*** Dmg)] × Role Advantage DMG × (1 - Action DMG Res)
                × Evasion Reduction****
              </Alert>
              <ul className="list-unstyled">
                <li>* only when crit; can't go below 1</li>
                <li>** can't go below 0.20</li>
                <li>*** only vs AoE attacks, and Skills/Ults respectively</li>
                <li>**** only if the attack is evaded</li>
              </ul>
              <p>
                This shows that as the ‘attacker’, you want to have as many DMG
                multiplier as possible:
              </p>
              <ul>
                <li>
                  Having ground dmg is generally more useful than air dmg as
                  most fights occur on the ground.
                </li>
                <li>
                  Having role dmg will boost your dmg on that specific role
                  ‘opponent’, good for stages where you know what role your main
                  ‘opponent’ has (defender/striker/ranger/etc.).
                </li>
                <li>
                  Having type dmg will boost your dmg on that specific type
                  ‘opponent’, good for stages where you know what type your main
                  ‘opponent’ has (counter/soldier/mech/c.o.).
                </li>
                <li>
                  Having CDMG will definitely improve your damage but this is
                  still tied to your CRIT rate and whether your ‘opponent’
                  evades your attack or not.
                </li>
              </ul>
              <p>
                While as the ‘opponent’ (the one receiving damage), you want to
                have as much RES as possible:
              </p>
              <ul>
                <li>
                  Having ground res is generally more useful than air res as
                  most fights occur on the ground.
                </li>
                <li>
                  Having role res will boost your survival against that specific
                  role of ‘attacker’, good for stages where you know what role
                  your main ‘attacker’ has (sniper/striker/ranger/etc.).
                </li>
                <li>
                  Having type res will boost your survival on that specific type
                  of ‘attacker’, good for stages where you know what type your
                  main ‘attacker’ has (counter/soldier/mech/c.o.).
                </li>
                <li>
                  Having universal and AoE res is better than specific res
                  because they are calculated outside the “max 80%” res type.
                </li>
              </ul>
            </div>
            <div className="section-scroll" id="gear-tuning">
              <SectionHeader title="Gear Tuning" />
              <p>
                Gear tuning needs binaries which are hard to get early game and
                will still be to the end if your luck is bad. For this reason
                you need to be wise on which gears you tune and not.
              </p>
              <p>What gear to use Set Binaries on?</p>
              <ul>
                <li>Prioritize set tuning your maze gear for counter first</li>
                <li>
                  Tune your maze hands to aspd/cdr but can stop at atk/cdmg and
                  tune other gears first.
                </li>
                <li>
                  Tune your maze case to hp/aspd/cdr but can stop at atk/cdmg
                  and tune other gears first.
                </li>
                <li>
                  Tune your maze crowns to aspd/cdr but can stop at atk/cdmg and
                  tune other gears first.
                </li>
                <li>
                  Tune your humming hands to hp/cdr but can stop at aspd and
                  tune other gears first.
                </li>
                <li>
                  Tune your gordias last, aim for hp/cdr but can stop at aspd
                  and tune other gears first.
                </li>
                <li>
                  Once your counter gears are set, you can also work on soldier
                  and mech.
                </li>
              </ul>
              <p>
                Use T1- T6 Dante gears as they are, do not tune/enhance (they
                are placeholder gears until you get better ones).
              </p>
              <p>
                Here are some lists of gear tuning based on personal experience:
              </p>
              <h5>ASPD Gear</h5>
              <p>
                Use Sc. Dante asd for raw dmg (cdmg 1st substat + ground dmg 2nd
                substat):
              </p>
              <div className="multiple-images">
                <StaticImage
                  src="../../../images/counterside/gearing/image6.webp"
                  alt="Beginner gearing"
                />
                <StaticImage
                  src="../../../images/counterside/gearing/image9.webp"
                  alt="Beginner gearing"
                />
              </div>
              <p>
                Use maze aspd if you also need haste (ground dmg 1st substat +
                haste 2nd substat):
              </p>
              <div className="multiple-images">
                <StaticImage
                  src="../../../images/counterside/gearing/image10.webp"
                  alt="Beginner gearing"
                />
                <StaticImage
                  src="../../../images/counterside/gearing/image27.webp"
                  alt="Beginner gearing"
                />
              </div>
              <p>Use Swift aspd for max aspd (can get 40%+ aspd in total).</p>
              <p>Use any random aspd gear to get a 20% aspd from set.</p>
              <h5>CDR Gear</h5>
              <p>
                Use Sc. Dante cdr for budget cdr (cdmg 1st substat + haste 2nd
                substat) remember that the gear can’t get haste substats on
                hands. (example was using T5 Dante hands)
              </p>
              <div className="multiple-images">
                <StaticImage
                  src="../../../images/counterside/gearing/image2.webp"
                  alt="Beginner gearing"
                />
                <StaticImage
                  src="../../../images/counterside/gearing/image22.webp"
                  alt="Beginner gearing"
                />
              </div>
              <p>
                Use maze cdr for max haste (ground dmg 1st substat + haste 2nd
                substat) this one can go to 72% (T6) or 76.2% (T7).
              </p>
              <div className="multiple-images">
                <StaticImage
                  src="../../../images/counterside/gearing/image10.webp"
                  alt="Beginner gearing"
                />
                <StaticImage
                  src="../../../images/counterside/gearing/image20.webp"
                  alt="Beginner gearing"
                />
              </div>
              <p>
                Use any humming/maze/gordias/whatever that has haste substat to
                get more than 30% using non-set.
              </p>
              <p>Use any random cdr gear to get a 30% haste from the set.</p>
              <h5>Spectral Gear</h5>
              <ul>
                <li>
                  They are the best cheap offensive gears against enemies with
                  class roles (defender/striker/etc.).
                </li>
                <li>1st substat can get anti-role dmg.</li>
                <li>2nd substat can get anti-ground dmg.</li>
                <li>
                  You can farm/craft a lot of spectral gears weekly so do not
                  use set binaries on them, tune their substats as you see fit.
                </li>
              </ul>
              <div className="multiple-images in-line">
                <StaticImage
                  src="../../../images/counterside/gearing/image5.webp"
                  alt="Beginner gearing"
                />
                <StaticImage
                  src="../../../images/counterside/gearing/image13.webp"
                  alt="Beginner gearing"
                />
                <StaticImage
                  src="../../../images/counterside/gearing/image25.webp"
                  alt="Beginner gearing"
                />
              </div>
              <h5>Defensive Gear</h5>
              <p>
                You don't need a set at start, aim for the substats, having
                additional 20%hp/def/eva from set is not worth more than having
                a complete aspd/cdr gear first (and some units can profit more
                by using defensive aspd or defensive cdr gear).
              </p>
              <p>
                On the topic of hp/def/eva discussion, generally hp is better as
                only certain units have a big enough base DEF or EVA stat to
                profit from def/eva set.
              </p>
              <p>
                Example: lv100 Hilde (SR) is using this gear for lv150 Inhibitor
                raid and she’s alive until the end as the foremost frontliner
                (the one getting hit by the boss’ single target basic attack)
              </p>
              <div className="multiple-images">
                <StaticImage
                  src="../../../images/counterside/gearing/image3.webp"
                  alt="Beginner gearing"
                />
                <StaticImage
                  src="../../../images/counterside/gearing/image4.webp"
                  alt="Beginner gearing"
                />
              </div>
              <h6>Defensive Weapon</h6>
              <p>
                Inhibitor best, no need set (of course better if you have set,
                but already good without), get the ground res on 2nd substat,
                try latent to boost survival more
              </p>
              <p>
                How about Humming? they are better as defensive cdr/aspd gear
                (they can have haste on 2nd substat)
              </p>
              <p>Other gears? Use cdmg res substat on weapon</p>
              <div className="multiple-images">
                <StaticImage
                  src="../../../images/counterside/gearing/image1.webp"
                  alt="Beginner gearing"
                />
                <StaticImage
                  src="../../../images/counterside/gearing/image11.webp"
                  alt="Beginner gearing"
                />
              </div>
              <h6>Defensive Armor</h6>
              <p>
                Maze has more cdmg res, but Inhibitor can get ranged/melee res
                from latent, while Polymer can get type res from 1st substat.
              </p>
              <p>
                So what to use? if you have hp maze, use it, if not, Inhibitor
                armor can get 16% ground res on 2nd substat with no latent.
              </p>
              <div className="multiple-images">
                <StaticImage
                  src="../../../images/counterside/gearing/image1.webp"
                  alt="Beginner gearing"
                />
                <StaticImage
                  src="../../../images/counterside/gearing/image11.webp"
                  alt="Beginner gearing"
                />
              </div>
              <h6>Defensive Accessory</h6>
              <p>This piece depends on how much resources will you spend.</p>
              <ul>
                <li>
                  How about Inhibitor? you need to use tuning bins to get ground
                  res, if you managed that, T7 Inhibitor has more ground res
                  than T6 Gordias (but no melee/ranged res on 1st substat)
                </li>
                <li>
                  How about Gordias? you need to spend tuning bins to get ground
                  res, if you managed that, the gordias is much better
                  non-latent ground res Inhibitor cause gordias already has
                  melee/ranged res
                </li>
                <li>
                  How about Polymer? This gear has lower stats than the two
                  above, but has a lot of type res on 1st substat, this made the
                  gear a niche PvP gear
                </li>
              </ul>
              <div className="multiple-images in-line">
                <StaticImage
                  src="../../../images/counterside/gearing/image19.webp"
                  alt="Beginner gearing"
                />
                <StaticImage
                  src="../../../images/counterside/gearing/image21.webp"
                  alt="Beginner gearing"
                />
                <StaticImage
                  src="../../../images/counterside/gearing/image16.webp"
                  alt="Beginner gearing"
                />
              </div>
              <h5>Free Gear</h5>
              <p>
                The latest game version gave us TASKFORCE mission that rewards
                players with gears they can choose, this makes the whole grind
                for gears a lot easier and faster. The T3 gears are indeed low
                in stats, but they are a good placeholder gear you can use
                before you can get the much better T5/T6 counterpart. The T5
                Replica and Antagonist set are useful even until the end game so
                they are worth tuning for.
              </p>
              <h6>T5 Replica</h6>
              <div className="multiple-images">
                <StaticImage
                  src="../../../images/counterside/gearing/image8.webp"
                  alt="Beginner gearing"
                />
                <StaticImage
                  src="../../../images/counterside/gearing/image7.webp"
                  alt="Beginner gearing"
                />
              </div>
              <h6>T5 Antagonist</h6>
              <div className="multiple-images">
                <StaticImage
                  src="../../../images/counterside/gearing/image17.webp"
                  alt="Beginner gearing"
                />
                <StaticImage
                  src="../../../images/counterside/gearing/image24.webp"
                  alt="Beginner gearing"
                />
              </div>
            </div>
            <div className="section-scroll" id="gear-management">
              <SectionHeader title="Gear Management" />
              <p>
                We have limited slots for gears, and although you can increase
                the space to 1000+ it will still get cluttered if not managed
                properly. Some useful gears might even get buried among the
                rubble. Here is a quick rundown on how to keep your inventory in
                check. Remember that this is just a tip, not a must-follow rule.
                You might want to keep more/less gears depending on your
                experience later.
              </p>
              <h5>
                Maze/Gordias/Humming/Exclusive Equipment
                (EE)/Antagonist/Replica/Challenger/Seasonal gear
              </h5>
              <p>
                Keep every single one of these gears, do not throw them away.
                While it’s true that you can farm more EE gears when the stage
                is open and you can buy Antagonist/Replica more when the packs
                are available, the rest are only available in limited number.
              </p>
              <h5>ASPD gear</h5>
              <p>
                Try to get either offensive substats for your dps, or defensive
                substats for your utility units.{' '}
              </p>
              <p>
                Keep several (2-5) sets for each type (Counter/Soldier/Mech),
                obviously keep the best and the rest can be sold/used as enhance
                fodders, check their substats to determine.
              </p>
              <h5>CDR gear</h5>
              <p>
                While you want as much haste as possible in PvP so your units
                use their skills quick and earlier, in regular PvE a 60%+ is
                already fast enough.
              </p>
              <p>
                Keep plenty (10+) sets for Counter and 1-2 sets for Soldier/Mech
                each. As they will be used in raids and other similar contents.
              </p>
              <h5>Anti-X gear</h5>
              <p>
                Keep 1-2 sets for each type (Counter/Soldier/Mech) as this might
                be useful in certain scenarios.
              </p>
              <h5>Spectral Gear</h5>
              <p>
                Blaze/Bullet/Chain are the useful ones, while Spirit/Smite/Gear
                are too niche.
              </p>
              <p>
                Blaze gives you a balanced ATK and ASPD boost, Bullet and Chain
                are the better ATK set as they also boost HIT and CRIT
                respectively. Meanwhile Spirit gives too little haste to be
                impactful, not a single unit in the game needs Smite, and Gear
                boost EVA.
              </p>
              <p>
                Keep several (2-5) of the useful sets for each type
                (Counter/Soldier/Mech).
              </p>
              <h5>ATK gear</h5>
              <p>
                Keep 1 set for each type (Counter/Soldier/Mech), this gear is
                usually combined with an EE + 2 SP gear (like for
                Gaeun/Xiaolin).
              </p>
              <h5>Defensive gear</h5>
              <p>
                Keep several (2-5) sets for each type (Counter/Soldier/Mech).
                Some with hp/def/eva set, some with cdr set, and some with aspd
                set.
              </p>
            </div>
            <div className="section-scroll" id="gear-latent">
              <SectionHeader title="Gear Latent" />
              <p>
                T7 Relic gears (Britra, Inhibitor, Swift) can roll latent after
                their +2 / +5 / +7 enhancement.
              </p>
              <p>
                The first time (+2) will unlock a random stats while the other
                two (+5 and +7) will only improve the number (will not
                reroll/add different stats).
              </p>
              <p>
                While it's fun to have more stats on your gears, it gets
                frustrating to min-max them. More details will be explored
                further in the “Relic Gear and Latent Guide” guide:
              </p>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={2}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="Latent gear"
                  link="/counter-side/guides/latent-gear"
                  image={
                    <StaticImage
                      src="../../../images/counterside/categories/guide_latent.png"
                      alt="Latent gear"
                    />
                  }
                />
              </Row>
              <h5>A word of warning</h5>
              <p>
                Gear tuning has no pity, unlike when you roll for a unit. This
                means that improper investment can potentially brick your
                account. Tip for new players will be:
              </p>
              <p>
                Always try to ask people with better knowledge and experience
                before committing to use your tuning/set binaries and before you
                sell/enhance a gear. We have a discord server with lots of
                helpful people willing to share, so do check it out.
              </p>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'foreword',
                'importance-of-gear',
                'gear-stats',
                'gear-tuning',
                'gear-management',
                'gear-latent'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#foreword">Foreword</a>
                </li>
                <li>
                  <a href="#importance-of-gear">Importance of Gear</a>
                </li>
                <li>
                  <a href="#gear-stats">Gear Stats</a>
                </li>
                <li>
                  <a href="#gear-tuning">Gear Tuning</a>
                </li>
                <li>
                  <a href="#gear-management">Gear Management</a>
                </li>
                <li>
                  <a href="#gear-latent">Gear Latent</a>
                </li>
              </ul>
            </ScrollspyNav>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default CSGearForDummiesPage;

export const Head: React.FC = () => (
  <Seo
    title="Basic gearing guide | Counter Side | Prydwen Institute"
    description="Basic guide for gearing in Counter Side."
  />
);
